<template>
  <el-table border ref="table" size="small" :data="list">
    <el-table-column type="index" width="100" align="center"></el-table-column>
    <el-table-column label="预览" prop="Title" width="200">
      <template slot-scope="scope">
        <el-image :src="domain + scope.row.Url" :preview-src-list="[domain + scope.row.Url]" fit="cover"
          style="width: 160px;height:90px;">
        </el-image>
      </template>
    </el-table-column>
    <el-table-column label="标题" prop="Name">
      <template slot-scope="scope">
        <el-button type="text" @click="previewFile(scope.row)">{{scope.row.Name}}</el-button>
      </template>
    </el-table-column>
    <el-table-column label="类型" prop="Type" width="100"></el-table-column>
    <el-table-column label="上传日期" prop="CreateTime" width="140"></el-table-column>
    <el-table-column label="操作" width="100" align="left">
      <template slot-scope="scope">
        <el-button @click="delFile(scope.row)" type="text" class="oa-text-danger hover">
          删除
        </el-button>
        <el-button @click="downloadFile(scope.row)" type="text" class="oa-text-success hover">
          下载
        </el-button>
        <el-button @click="sortFile(scope.row, 'up')" type="text" style="margin-left:0;">
          上移
        </el-button>
        <el-button @click="sortFile(scope.row, 'down')" type="text">
          下移
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import API from '../../../apis/ticket-api'

// 组件：项目轮播图
export default {
  name: 'ProjectImageTable',
  props: {
    project: { type: Object, default: null }
  },
  data () {
    return {
      domain: API.IMAGE_DOMAIN,
      list: [] // 列表
    }
  },
  methods: {
    init () {
      this.get()
    },
    get () {
      const url = API.PERSONAL.PROJECT.IMAGE.replace(/{id}/, this.project.Id)
      this.axios.get(url)
        .then(response => {
          this.list = response
        })
    },
    delFile (entity) {
      const url = API.PERSONAL.PROJECT.IMAGE_DELETE.replace(/{id}/, this.project.Id)
      this.axios.patch(url, [entity.Id])
        .then(response => {
          if (response.Status) this.getFiles()
        })
    },
    sortFile (entity, type) {
      const url = API.PERSONAL.PROJECT.IMAGE_INDEX.replace(/{id}/, this.project.Id).replace(/{imageId}/, entity.Id)
      this.axios.patch(url + '?sotrType=' + type)
        .then(response => {
          if (response.Status) this.getFiles()
        })
    },
    previewFile (item) {
      let isPreview = false
      if (item.Type.indexOf('.jpg') > -1 || item.Type.indexOf('.png') > -1) {
        if (this.$listeners.preview) {
          isPreview = true
          this.$emit('preview', `${(this.domain + item.Url)}`)
        }
      }
      if (!isPreview) {
        window.open(`${(this.domain + item.Url)}`)
      }
    },
    downloadFile (entity) {
      const url = entity.Url
      this.axios.get(url, {
        responseType: 'blob'
      }).then(res => {
        const blob = new Blob([res])
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = entity.Title + entity.ExtensionName // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      })
    }
  }
}
</script>
