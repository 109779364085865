<template>
  <div :class="['oa-form', { 'disabled': disabled }]">
    <el-card content-position="left" class="form-header">
      <el-page-header @back="goBack" content="项目管理" size="small">
      </el-page-header>
      <div v-show="activeTab == 'base'" class="button-box">
        <span v-if="disabled">
          <!-- <el-button round type="primary" size="small" @click="disabled = false">
            <font-awesome-icon fas icon="edit"></font-awesome-icon>&nbsp;编辑
          </el-button> -->
        </span>
        <span v-else>
          <el-button round type="primary" size="small" @click="submit">
            <font-awesome-icon fas icon="save"></font-awesome-icon>&nbsp;保存
          </el-button>
          <el-button round type="warning" size="small" @click="disabled = true">
            <font-awesome-icon fas icon="reply"></font-awesome-icon>&nbsp;取消
          </el-button>
        </span>
      </div>
    </el-card>
    <div class="form-content">
      <el-card content-position="left">
        <el-tabs v-model="activeTab" @tab-click="tabClick">
          <el-tab-pane name="base">
            <span slot="label">
              <font-awesome-icon fas icon="location-arrow"></font-awesome-icon>
              &nbsp;展会活动
            </span>
            <detail v-model="entity" :disabled="disabled" :isAdd="isAdd" ref="form"></detail>
          </el-tab-pane>
          <el-tab-pane name="project">
            <span slot="label">
              <font-awesome-icon fas icon="comment-dollar"></font-awesome-icon>
              &nbsp;注册项目
            </span>
            <project v-model="entity" :disabled="disabled" ref="project"></project>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>

<script>
import { PERSONAL_ACTIVITY, PERSONAL_ACTIVITY_FORM } from '../../router/base-router'
import { ROLE } from '../../assets/js/const'
import Detail from './Detail.vue'
import Project from './Project.vue'

export default {
  name: PERSONAL_ACTIVITY_FORM.name,
  data () {
    return {
      roles: ROLE,
      loading: false, // 加载中
      isAdd: false, // 是否添加
      activeTab: 'base', // 激活的标签
      disabled: true, // 允许编辑
      entity: {} // 实体
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.init())
  },
  methods: {
    init () {
      if (!this.loading) {
        this.loading = true
        this.isAdd = this.$route.params.isAdd
        this.entity = {
          ...this.$route.params
        }
        if (this.isAdd) {
          this.disabled = false
        } else {
          this.tabClick()
        }
        this.loading = false
      }
    },
    tabClick () {
      switch (this.activeTab) {
        case 'base': this.$refs.form.init(); break
        case 'project': this.$refs.project.init(); break
        case 'user': this.$refs.user.init(); break
      }
    },
    submit () {
      this.$refs.form.submit()
    },
    goBack () {
      this.$router.push(PERSONAL_ACTIVITY)
    }
  },
  mounted () {
    this.init()
  },
  components: {
    Detail,
    Project
  }
}
</script>
