<template>
  <el-table border :data="list" height="400" row-key="Id" ref="table" size="small" class="oa-table">
    <el-table-column sortable prop="OutTradeNo" label="订单号" width="200"></el-table-column>
    <el-table-column sortable prop="PayStatus" label="付款状态" width="200">
      <template slot-scope="scope">
        {{payTypes[scope.row.PayStatus].label}}
      </template>
    </el-table-column>
    <el-table-column sortable prop="UserName" label="用户姓名" width="200"></el-table-column>
    <el-table-column sortable prop="PayTime" label="付款时间" width="200"></el-table-column>
    <el-table-column sortable prop="TransactionId" label="付款订单号" width="200"></el-table-column>
    <el-table-column sortable prop="CipherText" label="付款回调信息" width="200"></el-table-column>
    <el-table-column sortable prop="CreateTime" label="创建日期" width="200"></el-table-column>
  </el-table>
</template>

<script>
import { PAY_TYPE } from '../../../assets/js/const'
import API from '../../../apis/ticket-api'

// 组件：项目订单
export default {
  name: 'ProjectOrderTable',
  props: {
    project: { type: Object, default: null }
  },
  data () {
    return {
      payTypes: PAY_TYPE, // 支付类型
      list: [] // 列表
    }
  },
  methods: {
    init () {
      this.get()
    },
    get () {
      const url = API.PERSONAL.PROJECT.ORDER.replace(/{id}/, this.project.Id)
      this.axios.get(url)
        .then(response => {
          this.list = response
        })
    },
    export () {
      const url = API.PERSONAL.PROJECT_ORDER_EXCEL.replace(/{id}/, this.project.Id)
      this.axios.get(url, {
        responseType: 'blob'
      }).then(res => {
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        })
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = '项目订单.xlsx' // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      })
    }
  }
}
</script>
