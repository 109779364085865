<template>
  <div>
    <el-table border :data="list" height="400" row-key="Id" ref="table" size="small" class="oa-table">
      <el-table-column sortable prop="Name" label="姓名" width="100"></el-table-column>
      <el-table-column prop="RegisterCode" label="注册码" width="100"></el-table-column>
      <el-table-column prop="Mobile" label="手机号码" width="100"></el-table-column>
      <el-table-column prop="Email" label="邮件地址" width="200"></el-table-column>
      <el-table-column prop="IdCard" label="身份证号码" width="200"></el-table-column>
      <el-table-column sortable prop="IdCardVerify" label="实名认证" width="100">
        <template slot-scope="scope">
          {{scope.row.IdCardVerify? '是' : '否'}}
        </template>
      </el-table-column>
      <el-table-column sortable prop="FirstName" label="名" width="80"></el-table-column>
      <el-table-column sortable prop="LastName" label="姓" width="80"></el-table-column>
      <el-table-column sortable prop="Company" label="公司" width="200"></el-table-column>
      <el-table-column sortable prop="Department" label="部门" width="100"></el-table-column>
      <el-table-column sortable prop="Title" label="职务" width="100"></el-table-column>
      <el-table-column prop="CompanyAddress" label="公司地址" width="200"></el-table-column>
      <el-table-column sortable prop="Country" label="所在国家" width="100"></el-table-column>
      <el-table-column sortable prop="Province" label="所在省份" width="100"></el-table-column>
      <el-table-column sortable prop="City" label="所在城市" width="100"></el-table-column>
      <el-table-column sortable prop="RegisterTime" label="注册时间" width="140"></el-table-column>
      <el-table-column sortable prop="IsChecked" label="是否检票" width="100">
        <template slot-scope="scope">
          {{scope.row.IsChecked? '是' : '否'}}
        </template>
      </el-table-column>
      <el-table-column sortable prop="CheckedTime" label="检票时间" width="140"></el-table-column>
      <el-table-column fixed="right" label="问卷" width="100" align="center">
        <template slot-scope="scope">
          <el-popover placement="right" width="680" trigger="click">
            <el-table stripe :data="scope.row.Questionnaires" size="small">
              <el-table-column property="Title" label="问题"></el-table-column>
              <el-table-column property="Answer" label="答案">
                <template slot-scope="scope">
                  <div v-for="item in scope.row.Answer" :key="item.id">{{item}}</div>
                </template>
              </el-table-column>
              <el-table-column property="Other" label="其他"></el-table-column>
            </el-table>
            <el-button slot="reference" type="text">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" @size-change="pageSizeChange"
      @current-change="pageIndexChange">
    </el-pagination>
  </div>
</template>

<script>
import API from '../../../apis/ticket-api'

// 组件：项目注册用户
export default {
  name: 'ProjectUserTable',
  props: {
    project: { type: Object, default: null }
  },
  data () {
    return {
      list: [], // 列表
      total: 1, // 总数据量
      pageIndex: 1, // 页码
      pageSize: 50 // 页数
    }
  },
  methods: {
    init () {
      this.get()
    },
    get () {
      const url = API.PERSONAL.PROJECT.USER.replace(/{id}/, this.project.Id)
      this.axios.get(`${url}/${this.pageIndex}/${this.pageSize}`)
        .then(response => {
          this.total = response.Total
          this.list = response.Items
          this.loading = false
        })
    },
    pageSizeChange (value) {
      this.pageSize = value
      this.$nextTick(() => this.get())
    },
    pageIndexChange (value) {
      this.pageIndex = value
      this.$nextTick(() => this.get())
    },
    export () {
      const url = API.PERSONAL.PROJECT.USER_EXCEL.replace(/{id}/, this.project.Id)
      this.axios.get(url, {
        responseType: 'blob'
      }).then(res => {
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        })
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = '注册用户.xlsx' // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      })
    }
  }
}
</script>
