<template>
  <div>
    <el-button-group class="btn-box">
      <!-- <el-button @click="add" size="small" class="oa-button">
        <font-awesome-icon fas icon="plus"></font-awesome-icon>&nbsp;添加
      </el-button> -->
      <!-- <el-button @click="update" size="small" class="oa-button">
        <font-awesome-icon fas icon="edit"></font-awesome-icon>&nbsp;修改
      </el-button> -->
      <el-button @click="showUserDialog" size="small" class="oa-button oa-button-success">
        <font-awesome-icon fas icon="users"></font-awesome-icon>&nbsp;注册用户
      </el-button>
      <el-button @click="showOrderDialog" size="small" class="oa-button oa-button-success">
        <font-awesome-icon fas icon="comment-dollar"></font-awesome-icon>&nbsp;订单
      </el-button>
      <el-button @click="showTicketDialog" size="small" class="oa-button oa-button-success">
        <font-awesome-icon fas icon="ticket-alt"></font-awesome-icon>&nbsp;门票
      </el-button>
      <!-- <el-button @click="update" size="small" class="oa-button">
        <font-awesome-icon fas icon="edit"></font-awesome-icon>&nbsp;修改
      </el-button> -->
      <!-- <el-button @click="del" size="small" class="oa-button oa-button-danger">
        <font-awesome-icon fas icon="trash"></font-awesome-icon>&nbsp;删除
      </el-button> -->
      <!-- <el-button @click="showImageDialog" size="small" class="oa-button">
        <font-awesome-icon fas icon="images"></font-awesome-icon>&nbsp;设置轮播图
      </el-button> -->
      <!-- <el-button @click="showQuestionnaireDialog" size="small" class="oa-button">
        <font-awesome-icon fas icon="file-signature"></font-awesome-icon>&nbsp;设置问卷
      </el-button> -->
    </el-button-group>
    <!-- 列表 -->
    <el-table border :data="list" @selection-change="selectionChange" @row-dblclick="rowDblClick" row-key="Id"
      ref="table" size="small" class="oa-table">
      <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
      <el-table-column sortable prop="Name" label="项目名称" width="200">
        <template slot-scope="scope">
          <el-button type="text" @click="rowDblClick(scope.row)">{{scope.row.Name}}</el-button>
        </template>
      </el-table-column>
      <el-table-column sortable prop="StartTime" label="开始时间" width="200"></el-table-column>
      <el-table-column sortable prop="EndTime" label="结束时间" width="200"></el-table-column>
      <el-table-column sortable prop="IdCardVerify" label="是否验证身份证" width="200">
        <template slot-scope="scope">
          {{scope.row.IdCardVerify? '是' : '否'}}
        </template>
      </el-table-column>
      <el-table-column sortable prop="IsSellTicket" label="是否售票" width="200">
        <template slot-scope="scope">
          {{scope.row.IsSellTicket? '是' : '否'}}
        </template>
      </el-table-column>
      <el-table-column prop="TicketName" label="票名" width="200"></el-table-column>
      <el-table-column prop="TicketLogo" label="LOGO" width="200"></el-table-column>
      <el-table-column prop="Price" label="单价" width="200"></el-table-column>
      <el-table-column prop="MinBuyQuantity" label="最小数量" width="200"></el-table-column>
      <el-table-column prop="MaxBuyQuantity" label="最大数量" width="200"></el-table-column>
      <el-table-column prop="HasWeixinPay" label="微信支付" width="100">
        <template slot-scope="scope">
          {{scope.row.HasWeixinPay? '是' : '否'}}
        </template>
      </el-table-column>
      <el-table-column prop="HasAliPay" label="支付宝支付" width="100">
        <template slot-scope="scope">
          {{scope.row.HasAliPay? '是' : '否'}}
        </template>
      </el-table-column>
      <el-table-column prop="Tips" label="注册提示信息" width="200"></el-table-column>
      <el-table-column prop="SuccessTips" label="成功页提示信息" width="200"></el-table-column>
      <el-table-column prop="Description" label="描述" width="200"></el-table-column>
      <el-table-column prop="CreateTime" label="创建日期" width="120"></el-table-column>
    </el-table>
    <!-- 表单 -->
    <el-dialog :visible.sync="dialogVisiable" title="活动项目" width="1080px" custom-class="oa-dialog">
      <el-form status-icon :model="entity" :rules="validationRule" ref="form" label-width="120px" class="form">
        <div class="row">
          <el-form-item label="项目名称" prop="Name">
            <el-input v-model="entity.Name" size="small" placeholder="请填写项目名称"></el-input>
          </el-form-item>
          <el-form-item label="开始时间" prop="StartTime">
            <el-date-picker v-model="entity.StartTime" type="datetime" format="yyyy年MM月dd日 HH:mm"
              value-format="yyyy-MM-dd HH:mm:ss" size="small" placeholder="请选择开展时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间" prop="EndTime">
            <el-date-picker v-model="entity.EndTime" type="datetime" format="yyyy年MM月dd日 HH:mm"
              value-format="yyyy-MM-dd HH:mm:ss" size="small" placeholder="请选择结束时间">
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="是否验证身份证" prop="IdCardVerify">
            <el-select v-model="entity.IdCardVerify" size="small" placeholder="是否验证身份证">
              <el-option :value="false" label="否"></el-option>
              <el-option :value="true" label="是"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-collapse v-model="activeFormcollapseName" accordion>
          <el-collapse-item v-if="entity.BaseInfoSetting" name="BaseInfoSetting">
            <template slot="title">
              <label class="oa-text-grey">基础信息设置</label>
            </template>
            <div class="row">
              <el-form-item label="电话">
                <el-checkbox v-model="entity.BaseInfoSetting.Mobile.Hide">隐藏</el-checkbox>
                <el-checkbox v-model="entity.BaseInfoSetting.Mobile.Required">必填</el-checkbox>
                <label class="oa-text-grey base-info-label">最小长度</label>
                <el-input v-model="entity.BaseInfoSetting.Mobile.MinLength" size="mini" placeholder="最小长度"
                  class="base-info-input"></el-input>
                <label class="oa-text-grey base-info-label">最大长度</label>
                <el-input v-model="entity.BaseInfoSetting.Mobile.MaxLength" size="mini" placeholder="最大长度"
                  class="base-info-input"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item label="邮箱">
                <el-checkbox v-model="entity.BaseInfoSetting.Email.Hide">隐藏</el-checkbox>
                <el-checkbox v-model="entity.BaseInfoSetting.Email.Required">必填</el-checkbox>
                <label class="oa-text-grey base-info-label">最小长度</label>
                <el-input v-model="entity.BaseInfoSetting.Email.MinLength" size="mini" placeholder="最小长度"
                  class="base-info-input"></el-input>
                <label class="oa-text-grey base-info-label">最大长度</label>
                <el-input v-model="entity.BaseInfoSetting.Email.MaxLength" size="mini" placeholder="最大长度"
                  class="base-info-input"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item label="身份证">
                <el-checkbox v-model="entity.BaseInfoSetting.IdCard.Hide">隐藏</el-checkbox>
                <el-checkbox v-model="entity.BaseInfoSetting.IdCard.Required">必填</el-checkbox>
                <label class="oa-text-grey base-info-label">最小长度</label>
                <el-input v-model="entity.BaseInfoSetting.IdCard.MinLength" size="mini" placeholder="最小长度"
                  class="base-info-input"></el-input>
                <label class="oa-text-grey base-info-label">最大长度</label>
                <el-input v-model="entity.BaseInfoSetting.IdCard.MaxLength" size="mini" placeholder="最大长度"
                  class="base-info-input"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item label="姓名">
                <el-checkbox v-model="entity.BaseInfoSetting.Name.Hide">隐藏</el-checkbox>
                <el-checkbox v-model="entity.BaseInfoSetting.Name.Required">必填</el-checkbox>
                <label class="oa-text-grey base-info-label">最小长度</label>
                <el-input v-model="entity.BaseInfoSetting.Name.MinLength" size="mini" placeholder="最小长度"
                  class="base-info-input"></el-input>
                <label class="oa-text-grey base-info-label">最大长度</label>
                <el-input v-model="entity.BaseInfoSetting.Name.MaxLength" size="mini" placeholder="最大长度"
                  class="base-info-input"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item label="公司">
                <el-checkbox v-model="entity.BaseInfoSetting.Company.Hide">隐藏</el-checkbox>
                <el-checkbox v-model="entity.BaseInfoSetting.Company.Required">必填</el-checkbox>
                <label class="oa-text-grey base-info-label">最小长度</label>
                <el-input v-model="entity.BaseInfoSetting.Company.MinLength" size="mini" placeholder="最小长度"
                  class="base-info-input"></el-input>
                <label class="oa-text-grey base-info-label">最大长度</label>
                <el-input v-model="entity.BaseInfoSetting.Company.MaxLength" size="mini" placeholder="最大长度"
                  class="base-info-input"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item label="部门">
                <el-checkbox v-model="entity.BaseInfoSetting.Department.Hide">隐藏</el-checkbox>
                <el-checkbox v-model="entity.BaseInfoSetting.Department.Required">必填</el-checkbox>
                <label class="oa-text-grey base-info-label">最小长度</label>
                <el-input v-model="entity.BaseInfoSetting.Department.MinLength" size="mini" placeholder="最小长度"
                  class="base-info-input"></el-input>
                <label class="oa-text-grey base-info-label">最大长度</label>
                <el-input v-model="entity.BaseInfoSetting.Department.MaxLength" size="mini" placeholder="最大长度"
                  class="base-info-input"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item label="公司地址">
                <el-checkbox v-model="entity.BaseInfoSetting.CompanyAddress.Hide">隐藏</el-checkbox>
                <el-checkbox v-model="entity.BaseInfoSetting.CompanyAddress.Required">必填</el-checkbox>
                <label class="oa-text-grey base-info-label">最小长度</label>
                <el-input v-model="entity.BaseInfoSetting.CompanyAddress.MinLength" size="mini" placeholder="最小长度"
                  class="base-info-input"></el-input>
                <label class="oa-text-grey base-info-label">最大长度</label>
                <el-input v-model="entity.BaseInfoSetting.CompanyAddress.MaxLength" size="mini" placeholder="最大长度"
                  class="base-info-input"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item label="职位">
                <el-checkbox v-model="entity.BaseInfoSetting.Title.Hide">隐藏</el-checkbox>
                <el-checkbox v-model="entity.BaseInfoSetting.Title.Required">必填</el-checkbox>
                <label class="oa-text-grey base-info-label">最小长度</label>
                <el-input v-model="entity.BaseInfoSetting.Title.MinLength" size="mini" placeholder="最小长度"
                  class="base-info-input"></el-input>
                <label class="oa-text-grey base-info-label">最大长度</label>
                <el-input v-model="entity.BaseInfoSetting.Title.MaxLength" size="mini" placeholder="最大长度"
                  class="base-info-input"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item label="地区">
                <el-checkbox v-model="entity.BaseInfoSetting.Region.Hide">隐藏</el-checkbox>
                <el-checkbox v-model="entity.BaseInfoSetting.Region.Required">必填</el-checkbox>
                <label class="oa-text-grey base-info-label">最小长度</label>
                <el-input v-model="entity.BaseInfoSetting.Region.MinLength" size="mini" placeholder="最小长度"
                  class="base-info-input"></el-input>
                <label class="oa-text-grey base-info-label">最大长度</label>
                <el-input v-model="entity.BaseInfoSetting.Region.MaxLength" size="mini" placeholder="最大长度"
                  class="base-info-input"></el-input>
              </el-form-item>
            </div>
          </el-collapse-item>
          <el-collapse-item name="TicketInfo">
            <template slot="title">
              <label class="oa-text-grey">售票相关</label>
            </template>
            <div class="row">
              <el-form-item label="是否售票" prop="IsSellTicket">
                <el-select v-model="entity.IsSellTicket" size="small" placeholder="是否售票">
                  <el-option :value="false" label="否"></el-option>
                  <el-option :value="true" label="是"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="票名" prop="TicketName">
                <el-input v-model="entity.TicketName" size="small" placeholder="请填写票名"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item label="票LOGO" prop="TicketLogo">
                <el-input v-model="entity.TicketLogo" size="small" placeholder="请填写票LOGO"></el-input>
                <el-upload multiple :action="uploadUrl" :headers="uploadHeader" :on-success="uploadTickLogoSuccess"
                  ref="upload" accept="">
                  <el-button type="text">点击上传</el-button>
                </el-upload>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item label="单价" prop="Price">
                <el-input v-model="entity.Price" size="small" placeholder="请填写单价"></el-input>
              </el-form-item>
              <el-form-item label="最低购票量" prop="MinBuyQuantity">
                <el-input v-model="entity.MinBuyQuantity" size="small" placeholder="请填写最低购票量"></el-input>
              </el-form-item>
              <el-form-item label="最高购票量" prop="MaxBuyQuantity">
                <el-input v-model="entity.MaxBuyQuantity" size="small" placeholder="请填写最高购票量"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item label="支付形式" prop="HasWeixinPay">
                <el-checkbox v-model="entity.HasWeixinPay" size="small" label="微信"></el-checkbox>
                <el-checkbox v-model="entity.HasAliPay" size="small" label="支付宝"></el-checkbox>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item label="注册提示信息" prop="Tips">
                <el-input v-model="entity.Tips" size="small" placeholder="请填写注册提示信息"></el-input>
              </el-form-item>
              <el-form-item label="成功页提示信息" prop="SuccessTips">
                <el-input v-model="entity.SuccessTips" size="small" placeholder="请填写成功页提示信息"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item prop="Description" label="备注" class="remark-item">
                <el-input show-word-limit v-model="entity.Description" :rows="5" type="textarea" size="small"
                  maxlength="300" placeholder="请输入备注"></el-input>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiable = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 注册用户 -->
    <el-dialog :visible.sync="userDialogVisiable" title="注册用户" width="1080px" custom-class="oa-dialog">
      <el-button-group class="btn-box">
        <el-button @click="exportUser" size="small" class="oa-button">
          <font-awesome-icon fas icon="plus"></font-awesome-icon>&nbsp;导出
        </el-button>
      </el-button-group>
      <user-table ref="userTable" :project="entity"></user-table>
    </el-dialog>
    <!-- 订单 -->
    <el-dialog :visible.sync="orderDialogVisiable" title="订单" width="1080px" custom-class="oa-dialog">
      <el-button-group class="btn-box">
        <el-button @click="exportOrder" size="small" class="oa-button">
          <font-awesome-icon fas icon="plus"></font-awesome-icon>&nbsp;导出
        </el-button>
      </el-button-group>
      <order-table ref="orderTable" :project="entity"></order-table>
    </el-dialog>
    <!-- 门票 -->
    <el-dialog :visible.sync="ticketDialogVisiable" title="门票" width="1080px" custom-class="oa-dialog">
      <el-button-group class="btn-box">
        <el-button @click="exportTicket" size="small" class="oa-button">
          <font-awesome-icon fas icon="plus"></font-awesome-icon>&nbsp;导出
        </el-button>
      </el-button-group>
      <ticket-table ref="ticketTable" :project="entity"></ticket-table>
    </el-dialog>
    <!-- 轮播图 -->
    <el-dialog :visible.sync="imgDialogVisiable" title="项目轮播图" width="1080px" custom-class="oa-dialog">
      <div class="btn-box">
        <el-button-group class="btn-box">
          <el-upload multiple :action="uploadUrl" :headers="uploadHeader" :show-file-list="true" :file-list="fileList"
            :on-success="uploadFileSuccess" ref="upload" accept="">
            <el-button type="primary" size="small">
              <font-awesome-icon fas icon="upload"></font-awesome-icon>&nbsp;上传
            </el-button>
          </el-upload>
        </el-button-group>
        <image-table ref="imageTable" :project="entity"></image-table>
      </div>
    </el-dialog>
    <!-- 问卷 -->
    <el-dialog :visible.sync="queDialogVisiable" @open="getQuestionnaires" title="用户问卷" width="1080px"
      custom-class="oa-dialog">
      <el-form status-icon ref="questionnaireForm" label-width="120px" class="form">
        <questionnaire v-model="questionSettings"></questionnaire>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="queDialogVisiable = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitQuestionnaire" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from '../../apis/ticket-api'
import Questionnaire from '../_components/Questionnaire.vue'
import UserTable from './_components/ProjectUserTable'
import OrderTable from './_components/ProjectOrderTable'
import TicketTable from './_components/ProjectTicketTable'
import ImageTable from './_components/ProjectImageTable'

// 活动：项目
export default {
  name: 'ActivityProject',
  props: {
    value: { type: Object, default: null }
  },
  data () {
    return {
      domain: API.IMAGE_DOMAIN,
      list: [], // 列表
      selections: [], // 选中值
      questionSettings: [], // 问卷
      fileList: [], // 上传中的文件
      loading: false, // 加载中
      dialogVisiable: false, // 表单显示
      userDialogVisiable: false, // 注册用户显示
      orderDialogVisiable: false, // 订单显示
      ticketDialogVisiable: false, // 门票显示
      imgDialogVisiable: false, // 轮播图显示
      queDialogVisiable: false, // 用户问卷显示
      activeFormcollapseName: 'BaseInfoSetting', // 激活的collapse名称
      isAdd: false, // 添加模式
      entity: {}, // 当前实体
      validationRule: {
        Name: [{ required: true, message: '请填写活动名称' }, { min: 2, max: 200, message: '活动名称长度为2-200个字符' }],
        StartTime: [{ required: true, message: '请选择开始时间' }],
        EndTime: [{ required: true, message: '请选择结束时间' }]
      }
    }
  },
  computed: {
    uploadUrl () {
      return API.ALI_OSS_UPLOAD.URL
    },
    uploadHeader () {
      return {
        Authorization: `bearer ${this.$store.getters.access_token}`
      }
    },
    maxSize () {
      return 10 * 1024 * 1024 * 1024
    }
  },
  methods: {
    init () {
      this.list = []
      this.fileList = []
      this.questionSettings = []
      this.selections = []
      if (!this.loading) {
        this.loading = true
        this.get()
      }
    },
    get () {
      const url = API.PERSONAL.ACTIVITY.PROJECT.replace(/{id}/, this.value.Id)
      this.axios.get(url).then(response => {
        this.list = response
        this.$nextTick(() => { this.loading = false })
      })
    },
    selectionChange (rows) {
      this.selections = rows
    },
    rowDblClick (row) {
      this.selections = [row]
      this.update()
    },
    add () {
      this.isAdd = true
      this.entity = {
        BaseInfoSetting: {
          Mobile: {},
          Email: {},
          IdCard: {},
          Name: {},
          Company: {},
          Department: {},
          CompanyAddress: {},
          Title: {},
          Region: {}
        }
      }
      this.dialogVisiable = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    update () {
      if (!this.checkHadSelection()) return false
      this.isAdd = false
      this.entity = { ...this.selections[0] }
      this.dialogVisiable = true
    },
    del () {
      if (!this.checkHadSelection()) return false
      const ids = this.selections.map(s => s.Id)
      const url = API.PERSONAL.ACTIVITY.PROJECT_BATCH_DELETE.replace(/{id}/, this.value.Id)
      this.axios.patch(url, ids)
        .then(response => {
          if (response.Status) this.init()
        })
    },
    uploadTickLogoSuccess (response) {
      if (response.Status) {
        this.entity.TicketLogo = this.domain + response.Data.Url
      } else {
        this.$message.error(response.Message)
      }
    },
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.submitAdd()
          } else {
            this.submitUpdate()
          }
        }
      })
    },
    submitAdd () {
      const url = API.PERSONAL.ACTIVITY.PROJECT.replace(/{id}/, this.value.Id)
      this.axios.post(url, this.entity)
        .then(response => {
          if (response.Status) {
            this.dialogVisiable = false
            this.get()
          }
        })
    },
    submitUpdate () {
      const url = API.PERSONAL.ACTIVITY.PROJECT.replace(/{id}/, this.value.Id)
      this.axios.put(url, this.entity)
        .then(response => {
          if (response.Status) {
            this.dialogVisiable = false
            this.get()
          }
        })
    },
    showUserDialog () {
      if (!this.checkHadSelection()) return false
      this.entity = { ...this.selections[0] }
      this.userDialogVisiable = true
      this.$nextTick(() => {
        this.$refs.userTable.init()
      })
    },
    exportUser () {
      this.$refs.userTable.export()
    },
    showOrderDialog () {
      if (!this.checkHadSelection()) return false
      this.entity = { ...this.selections[0] }
      this.orderDialogVisiable = true
      this.$nextTick(() => {
        this.$refs.orderTable.init()
      })
    },
    exportOrder () {
      this.$refs.orderTable.export()
    },
    showTicketDialog () {
      if (!this.checkHadSelection()) return false
      this.entity = { ...this.selections[0] }
      this.ticketDialogVisiable = true
      this.$nextTick(() => {
        this.$refs.ticketTable.init()
      })
    },
    exportTicket () {
      this.$refs.ticketTable.export()
    },
    showImageDialog () {
      if (!this.checkHadSelection()) return false
      this.isAdd = false
      this.entity = { ...this.selections[0] }
      this.imgDialogVisiable = true
      this.$nextTick(() => {
        this.$refs.imageTable.init()
      })
    },
    uploadFileSuccess (response) {
      if (response.Status) {
        const url = API.PERSONAL.PROJECT.IMAGE.replace(/{id}/, this.entity.Id)
        const entity = {
          Name: response.Data.Name,
          Type: response.Data.Extension,
          Url: response.Data.Url
        }
        this.axios.post(url, entity)
          .then(response => {
            if (response.Status) {
              this.$refs.imageTable.init()
            }
          })
      } else {
        this.$message.error(response.Message)
      }
    },
    showQuestionnaireDialog () {
      if (!this.checkHadSelection()) return false
      this.isAdd = false
      this.entity = { ...this.selections[0] }
      this.queDialogVisiable = true
    },
    getQuestionnaires () {
      const url = API.PERSONAL.PROJECT.QUESTION_SETTING.replace(/{id}/, this.entity.Id)
      this.axios.get(url)
        .then(response => {
          this.questionSettings = response
        })
    },
    submitQuestionnaire () {
      if (this.questionSettings.length > 0) {
        const url = API.PERSONAL.PROJECT.QUESTION_SETTING.replace(/{id}/, this.entity.Id)
        this.axios.put(url, this.questionSettings)
          .then(response => {
            if (response.Status) {
              this.queDialogVisiable = false
            }
          })
      } else {
        this.$message.error('请先创建问题')
      }
    },
    checkHadSelection () {
      if (this.selections.length < 1) {
        this.$message.error('请先选择要操作数据')
        return false
      }
      return true
    }
  },
  components: {
    Questionnaire,
    UserTable,
    OrderTable,
    TicketTable,
    ImageTable
  }
}
</script>

<style lang="scss" scoped>
.btn-box {
  margin-bottom: 10px;
}

.base-info-label {
  margin-right: 10px;
}

.base-info-input {
  margin-right: 30px;
}

.form {

  .remark-item {
    flex: 1;
  }

  .detail-row {
    display: flex;
    justify-content: center;
  }
}
</style>
