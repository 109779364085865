<template>
  <el-table border :data="list" height="400" row-key="Id" ref="table" size="small" class="oa-table">
    <el-table-column prop="TicketCode" label="注册码"></el-table-column>
    <el-table-column prop="IsSync" label="同步到现场" width="120">
      <template slot-scope="scope">
        {{scope.row.IdCardVerify? '是' : '否'}}
      </template>
    </el-table-column>
    <el-table-column prop="SyncTime" label="同步时间" width="140"></el-table-column>
    <el-table-column prop="IsChecked" label="是否检票" width="100">
      <template slot-scope="scope">
        {{scope.row.IdCardVerify? '是' : '否'}}
      </template>
    </el-table-column>
    <el-table-column prop="CheckedTime" label="检票时间" width="140"></el-table-column>
    <el-table-column prop="CreateTime" label="创建时间" width="140"></el-table-column>
  </el-table>
</template>

<script>
import API from '../../../apis/ticket-api'

// 组件：项目注册用户
export default {
  name: 'ProjectTicketTable',
  props: {
    project: { type: Object, default: null }
  },
  data () {
    return {
      list: [] // 列表
    }
  },
  methods: {
    init () {
      this.get()
    },
    get () {
      const url = API.PERSONAL.PROJECT.TICKET.replace(/{id}/, this.project.Id)
      this.axios.get(url)
        .then(response => {
          this.list = response
        })
    },
    export () {
      const url = API.PERSONAL.PROJECT.TICKET_EXCEL.replace(/{id}/, this.project.Id)
      this.axios.get(url, {
        responseType: 'blob'
      }).then(res => {
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        })
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = '项目门票.xlsx' // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      })
    }
  }
}
</script>
