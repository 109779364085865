<template>
  <div :class="['oa-form', { 'disabled': disabled }]">
    <el-form status-icon :disabled="disabled" :model="entity" :rules="validationRule" class="form" ref="form"
      label-width="120px">
      <div class="row">
        <el-form-item label="活动名称" prop="Name">
          <el-input v-model="entity.Name" size="small" placeholder="请填写活动名称"></el-input>
        </el-form-item>
        <el-form-item label="开展日期" prop="StartTime">
          <el-date-picker v-model="entity.StartTime" type="datetime" format="yyyy年MM月dd日 HH:mm"
            value-format="yyyy-MM-dd HH:mm:ss" size="small" placeholder="请选择开展日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="闭展日期" prop="EndTime">
          <el-date-picker v-model="entity.EndTime" type="datetime" format="yyyy年MM月dd日 HH:mm"
            value-format="yyyy-MM-dd HH:mm:ss" size="small" placeholder="请选择闭展日期">
          </el-date-picker>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="主办方" prop="OrganizerId">
          <el-select filterable remote reserve-keyword v-model="entity.OrganizerId" :remote-method="getOrganizers"
            @change="selectOrganizer" class="template-select" size="small" placeholder="请输入名称搜索主办方">
            <el-option v-for="item in organizers" :key="item.Id" :value="item.Id" :label="item.Name"
              style="width: 450px;">
              <label>{{item.Name}}</label><label v-if="item.StartDate" style="float:right;">
                {{new Date(item.StartDate).toString('yyyy-MM-dd')}}&nbsp;至&nbsp;
                {{new Date(item.EndDate).toString('yyyy-MM-dd')}}
              </label>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在展馆" prop="CenterName">
          <el-input v-model="entity.CenterName" size="small" placeholder="请填写所在展馆"></el-input>
        </el-form-item>
        <el-form-item label="展馆地址" prop="CenterAddress">
          <el-input v-model="entity.CenterAddress" size="small" placeholder="请填写展馆地址"></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="所在国家" prop="Country">
          <el-input v-model="entity.Country" size="small" placeholder="请填写所在国家"></el-input>
        </el-form-item>
        <el-form-item label="所在省份" prop="Province">
          <el-input v-model="entity.Province" size="small" placeholder="请填写所在省份"></el-input>
        </el-form-item>
        <el-form-item label="所在城市" prop="City">
          <el-input v-model="entity.City" size="small" placeholder="请填写所在城市"></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="描述" prop="Description" class="address-item">
          <el-input show-word-limit v-model="entity.Description" :rows="5" type="textarea" maxlength="300"
            placeholder="请输入描述">
          </el-input>
        </el-form-item>
      </div>
      <el-divider content-position="left">微信公众号相关配置</el-divider>
      <div class="row">
        <el-form-item label="Appid" prop="WeixinAppid">
          <el-input v-model="entity.WeixinAppid" size="small" placeholder="请填写公众号Appid"></el-input>
        </el-form-item>
        <el-form-item label="AppSecret" prop="WeixinAppSecret">
          <el-input v-model="entity.WeixinAppSecret" size="small" placeholder="请填写公众号AppSecret"></el-input>
        </el-form-item>
        <el-form-item label="微信商户号" prop="WeixinMchid">
          <el-input v-model="entity.WeixinMchid" size="small" placeholder="请填写微信商户号"></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="证书序列号" prop="WeixinSerialNo">
          <el-input v-model="entity.WeixinSerialNo" size="small" placeholder="请填写证书序列号"></el-input>
        </el-form-item>
        <el-form-item label="ApiV3秘钥" prop="WeixinApiV3Key">
          <el-input v-model="entity.WeixinApiV3Key" size="small" placeholder="请填写ApiV3秘钥"></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="证书私钥" prop="WeixinPrivateKey" class="address-item">
          <el-input show-word-limit v-model="entity.WeixinPrivateKey" :rows="5" type="textarea" maxlength="300"
            placeholder="请填写证书私钥">
          </el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="证书公钥" prop="WeixinPublicKey" class="address-item">
          <el-input show-word-limit v-model="entity.WeixinPublicKey" :rows="5" type="textarea" maxlength="300"
            placeholder="请填写证请填写证书公钥书私钥">
          </el-input>
        </el-form-item>
      </div>
      <el-divider content-position="left">支付宝相关配置</el-divider>
      <div class="row">
        <el-form-item label="Appid" prop="AliPayAppId">
          <el-input v-model="entity.AliPayAppId" size="small" placeholder="请填写支付宝AppId"></el-input>
        </el-form-item>
        <el-form-item label="AES秘钥" prop="AliPayAesKey">
          <el-input v-model="entity.AliPayAesKey" size="small" placeholder="请填写AES秘钥"></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="证书私钥" prop="AliPayPublicKey" class="address-item">
          <el-input show-word-limit v-model="entity.AliPayPublicKey" :rows="5" type="textarea" maxlength="300"
            placeholder="请填写证书私钥">
          </el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="证书公钥" prop="AliPayPublicKey" class="address-item">
          <el-input show-word-limit v-model="entity.AliPayPublicKey" :rows="5" type="textarea" maxlength="300"
            placeholder="请填写证请填写证书公钥书私钥">
          </el-input>
        </el-form-item>
      </div>
      <el-divider content-position="left">短信配置</el-divider>
      <div class="row">
        <el-form-item label="短信剩余数量" prop="SmsAmount">
          <el-input v-model="entity.SmsAmount" size="small" placeholder="请填写短信剩余数量"></el-input>
        </el-form-item>
        <el-form-item label="短信签名" prop="SmsSign">
          <el-input v-model="entity.SmsSign" size="small" placeholder="请填写短信签名"></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="验证码模板" prop="SmsVerifyTemplate" class="address-item">
          <el-input show-word-limit v-model="entity.SmsVerifyTemplate" :rows="5" type="textarea" maxlength="300"
            placeholder="请填写短信验证码模板">
          </el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="成功模板" prop="SmsSuccessTemplate" class="address-item">
          <el-input show-word-limit v-model="entity.SmsSuccessTemplate" :rows="5" type="textarea" maxlength="300"
            placeholder="请填写短信成功模板">
          </el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import API from '../../apis/ticket-api'
import { PERSONAL_ACTIVITY } from '../../router/base-router'

export default {
  name: 'ActivityForm',
  props: {
    value: { Type: Object },
    // 添加模式
    isAdd: { Type: Boolean, default: false },
    // 禁用
    disabled: { Type: Boolean, default: false }
  },
  data () {
    return {
      entity: {}, // 实体
      organizers: [], // 项目列表
      organizerSearchOption: { key: '' },
      organizersLoading: false, // 分销协议加载中
      validationRule: {
        Name: [{ required: true, message: '请填写活动名称' }, { min: 2, max: 200, message: '活动名称长度为2-200个字符' }],
        OrganizerId: [{ required: true, message: '请选择主办方' }],
        StartTime: [{ required: true, message: '请选择开展日期' }],
        EndTime: [{ required: true, message: '请选择闭展日期' }],
        CenterName: [{ max: 200, message: '所在展馆名称长度最大为200个字符' }],
        CenterAddress: [{ max: 200, message: '所在展馆地址长度最大为200个字符' }],
        Country: [{ max: 100, message: '国家最大为100个字符' }],
        Province: [{ max: 100, message: '省份最大为100个字符' }],
        City: [{ max: 100, message: '城市最大为100个字符' }],
        SmsSign: [{ max: 50, message: '短信签名最大为100个字符' }]
      }
    }
  },
  watch: {
    value (newValue) {
      this.init()
    },
    entity (newValue) {
      this.$emit('input', newValue)
    }
  },
  methods: {
    init () {
      this.organizers = []
      if (this.value) this.entity = this.value
      this.getOrganizer(this.entity.OrganizerId)
      this.$nextTick(() => { this.$refs.form.clearValidate() })
    },
    get () {
      this.axios.get(`${API.PERSONAL.ACTIVITY.URL}/${this.entity.Id}`)
        .then(response => {
          this.entity = response
        })
    },
    getOrganizer () {
      if (!this.entity.OrganizerId || this.entity.OrganizerId === this.$store.state.guid) return
      this.axios.get(`${API.ORGANIZER.URL}/${this.entity.OrganizerId}`).then(response => {
        if (response) {
          this.organizers = [response]
        } else {
          this.organizers = [{ Id: response.OrganizerId, Name: '已被删除' }]
        }
      })
    },
    getOrganizers () {
      this.organizersLoading = true
      this.axios.get(API.ORGANIZER.SELECTION, {
        params: this.organizerSearchOption
      }).then(response => {
        this.organizers = response
        this.organizersLoading = false
      })
    },
    selectOrganizer (id) {
      const organizer = this.organizers.find(w => w.Id === id)
      if (organizer) {
        this.entity.OrganizerId = organizer.Id
      }
    },
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.add()
          } else {
            this.update()
          }
        }
      })
    },
    add () {
      this.axios.post(API.PERSONAL.ACTIVITY.URL, this.entity)
        .then(response => {
          if (response.Status) {
            this.$router.push(PERSONAL_ACTIVITY)
          }
        })
    },
    update () {
      this.axios.put(API.PERSONAL.ACTIVITY.URL, this.entity)
        .then(response => {
          if (response.Status) {
            this.$router.push(PERSONAL_ACTIVITY)
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.form {

  .address-item {
    flex: 1;
    max-width: 1000px;

    .el-input,
    input {
      width: 100%;
    }
  }

  .remark-item {
    flex: 1;
    max-width: 1000px;
  }
}
</style>
